.highlight-container, .highlight {
position: relative;
}

.highlight-container {
display: inline-block;
}

.highlight-container:before {
content: " ";
display: block;
height: 90%;
width: 100%;
margin-left: -3px;
margin-right: -3px;
position: absolute;
background: #ffd500;
transform: rotate(2deg);
top: -1px;
left: -1px;
border-radius: 20% 25% 20% 24%;
padding: 10px 3px 3px 10px;
}

.font-color-blue {
    color: #2e3d62 !important;
}